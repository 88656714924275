'use client';
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';

interface UIContextType {
  mobileMenuOpen: boolean;
  setMobileMenuOpen: (open: boolean) => void;
  isProfileOpen: boolean;
  setIsProfileOpen: (open: boolean) => void;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  showError: (message: string) => void;
  errorMessage: string | null;
  clearError: () => void;
  showToast: (message: string, type?: 'success' | 'error' | 'info') => void;
}

const UIContext = createContext<UIContextType | undefined>(undefined);

export function UIProvider({ children }: { children: ReactNode }) {
  const [mounted, setMounted] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Only run on client side
  useEffect(() => {
    setMounted(true);
  }, []);

  const showError = (message: string) => {
    setErrorMessage(message);
    // Auto-clear after 5 seconds
    setTimeout(() => {
      setErrorMessage(null);
    }, 5000);
  };

  const clearError = () => {
    setErrorMessage(null);
  };

  // Add the showToast implementation
  const showToast = (
    message: string,
    type: 'success' | 'error' | 'info' = 'info'
  ) => {
    // Implementation can be expanded later
    console.log(`Toast: ${type} - ${message}`);
    // You could trigger a toast component here
  };

  // Don't render anything until mounted on client
  if (!mounted) {
    // Return a minimal structure to avoid layout shifts
    return <div style={{ visibility: 'hidden' }}>{children}</div>;
  }

  return (
    <UIContext.Provider
      value={{
        mobileMenuOpen,
        setMobileMenuOpen,
        isProfileOpen,
        setIsProfileOpen,
        isLoading,
        setIsLoading,
        showError,
        errorMessage,
        clearError,
        showToast,
      }}
    >
      {errorMessage && (
        <div className="fixed top-4 right-4 z-50 bg-red-600 text-white px-4 py-2 rounded shadow-lg">
          {errorMessage}
        </div>
      )}
      {isLoading && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-slate-800 rounded-lg p-4 flex items-center gap-3">
            <div className="w-5 h-5 border-2 border-t-transparent border-blue-500 rounded-full animate-spin"></div>
            <div className="text-white">Loading...</div>
          </div>
        </div>
      )}
      {children}
    </UIContext.Provider>
  );
}

// Safe hook to use UI context
export function useUI() {
  const context = useContext(UIContext);

  // Only throw in development mode and on client side
  if (
    process.env.NODE_ENV === 'development' &&
    typeof window !== 'undefined' &&
    context === undefined
  ) {
    console.warn('useUI called outside of UIProvider');
  }

  // Always return a valid object, whether context exists or not
  return (
    context || {
      mobileMenuOpen: false,
      setMobileMenuOpen: () => {},
      isProfileOpen: false,
      setIsProfileOpen: () => {},
      isLoading: false,
      setIsLoading: () => {},
      showError: () => {},
      errorMessage: null,
      clearError: () => {},
      showToast: () => {},
    }
  );
}
