import React, { createContext, useState, useContext, useEffect } from 'react';
import { fetchWithAuth } from '@/lib/fetchWithAuth';

export interface Notification {
  id: string;
  type: 'system' | 'order' | 'price' | 'forum' | 'message' | 'like';
  title: string;
  message: string;
  createdAt: string;
  read: boolean;
  link: string;
  metadata?: Record<string, any>;
}

interface NotificationContextType {
  notifications: Notification[];
  unreadCount: number;
  fetchNotifications: () => Promise<void>;
  markAsRead: (notificationId: string) => Promise<boolean>;
  markAllAsRead: () => Promise<boolean>;
  clearNotifications: () => void;
  isLoading: boolean;
  error: string | null;
}

const NotificationContext = createContext<NotificationContextType>({
  notifications: [],
  unreadCount: 0,
  fetchNotifications: async () => {},
  markAsRead: async () => false,
  markAllAsRead: async () => false,
  clearNotifications: () => {},
  isLoading: false,
  error: null,
});

export function NotificationProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [mounted, setMounted] = useState(false);

  // Compute unread count
  const unreadCount = notifications.filter((n) => !n.read).length;

  // Fetch notifications on mount
  useEffect(() => {
    setMounted(true);
    fetchNotifications();

    // Set up polling to check for new notifications (every 2 minutes)
    const intervalId = setInterval(() => fetchNotifications(), 2 * 60 * 1000);

    return () => clearInterval(intervalId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchNotifications = async () => {
    if (!mounted) return;

    try {
      setIsLoading(true);
      setError(null);

      const response = await fetchWithAuth('/api/notifications');
      const data = await response.json();

      if (data.success) {
        setNotifications(data.notifications || []);
      } else {
        setError(data.message || 'Failed to fetch notifications');
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
      setError('Failed to load notifications');
    } finally {
      setIsLoading(false);
    }
  };

  const markAsRead = async (notificationId: string): Promise<boolean> => {
    try {
      const response = await fetchWithAuth('/api/notifications/mark-read', {
        method: 'POST',
        body: JSON.stringify({ notificationId }),
      });

      const data = await response.json();

      if (data.success) {
        // Update local state
        setNotifications((prevNotifications) =>
          prevNotifications.map((notification) =>
            notification.id === notificationId
              ? { ...notification, read: true }
              : notification
          )
        );
        return true;
      }

      return false;
    } catch (error) {
      console.error('Error marking notification as read:', error);
      return false;
    }
  };

  const markAllAsRead = async (): Promise<boolean> => {
    try {
      const response = await fetchWithAuth('/api/notifications/mark-read', {
        method: 'POST',
        body: JSON.stringify({ all: true }),
      });

      const data = await response.json();

      if (data.success) {
        // Update local state
        setNotifications((prevNotifications) =>
          prevNotifications.map((notification) => ({
            ...notification,
            read: true,
          }))
        );
        return true;
      }

      return false;
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
      return false;
    }
  };

  const clearNotifications = () => {
    setNotifications([]);
  };

  if (!mounted) {
    return <>{children}</>;
  }

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        unreadCount,
        fetchNotifications,
        markAsRead,
        markAllAsRead,
        clearNotifications,
        isLoading,
        error,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}

export const useNotifications = () => useContext(NotificationContext);
