// lib/fetchWithAuth.ts
'use client';

export const fetchWithAuth = async (url: string, options: RequestInit = {}) => {
  // Get user from localStorage
  const userStr = localStorage.getItem('user');
  if (!userStr) {
    console.error('Not authenticated: No user in localStorage');
    throw new Error('User not authenticated');
  }

  try {
    const user = JSON.parse(userStr);
    console.log('Auth user data:', user);

    // Get the user ID, checking both id and _id fields
    const userId = user.id || user._id;
    if (!userId) {
      console.error('Invalid user data - no ID found:', user);
      throw new Error('Invalid user data');
    }

    // Create headers with the user ID
    const headers = new Headers(options.headers || {});
    headers.set('user-id', userId);
    headers.set('Content-Type', 'application/json');

    console.log('Request to:', url);
    console.log('Headers:', Object.fromEntries(headers.entries()));

    // Make the fetch request
    const response = await fetch(url, {
      ...options,
      headers,
      credentials: 'include',
    });

    // Handle non-OK responses
    if (!response.ok) {
      const errorData = await response
        .json()
        .catch(() => ({ message: 'Unknown error' }));
      console.error('API error response:', errorData);
      throw new Error(errorData.message || `API error: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.error('fetchWithAuth error:', error);
    throw error;
  }
};
