import { useState } from 'react';
import Link from 'next/link';
import { motion } from 'framer-motion';
import {
  Bell,
  X,
  Settings,
  ShoppingBag,
  MessageCircle,
  Tag,
  Heart,
  AlertCircle,
} from 'lucide-react';
import { Button } from '@/components/ui/button';

interface NotificationPanelProps {
  onClose: () => void;
}

type Notification = {
  id: string;
  type: 'system' | 'message' | 'order' | 'price' | 'forum' | 'like';
  title: string;
  message: string;
  time: string;
  read: boolean;
  link: string;
};

export function NotificationPanel({ onClose }: NotificationPanelProps) {
  // Mock notifications - in a real app, you'd fetch these from your API
  const [notifications, setNotifications] = useState<Notification[]>([
    {
      id: '1',
      type: 'order',
      title: 'Order Confirmed',
      message: 'Your order #WV12345 has been confirmed and is being processed.',
      time: '10 min ago',
      read: false,
      link: '/purchases',
    },
    {
      id: '2',
      type: 'price',
      title: 'Price Alert',
      message: 'Rolex Submariner prices have decreased by 5% in the last week.',
      time: '3 hours ago',
      read: false,
      link: '/charts/rolex-submariner',
    },
    {
      id: '3',
      type: 'forum',
      title: 'New Reply',
      message: 'Someone replied to your thread "Best vintage Omega models"',
      time: '1 day ago',
      read: true,
      link: '/forums/threads/best-vintage-omega-models',
    },
    {
      id: '4',
      type: 'message',
      title: 'New Message',
      message: 'You have a new message from the seller regarding your inquiry.',
      time: '2 days ago',
      read: true,
      link: '/messages',
    },
    {
      id: '5',
      type: 'system',
      title: 'System Update',
      message: 'Watch Inspect has been updated with new features.',
      time: '1 week ago',
      read: true,
      link: '/updates',
    },
  ]);

  const markAllAsRead = () => {
    setNotifications((prev) =>
      prev.map((notification) => ({ ...notification, read: true }))
    );
  };

  const markAsRead = (id: string) => {
    setNotifications((prev) =>
      prev.map((notification) =>
        notification.id === id ? { ...notification, read: true } : notification
      )
    );
  };

  const getNotificationIcon = (type: string) => {
    switch (type) {
      case 'order':
        return <ShoppingBag className="h-5 w-5 text-green-400" />;
      case 'price':
        return <Tag className="h-5 w-5 text-yellow-400" />;
      case 'forum':
        return <MessageCircle className="h-5 w-5 text-blue-400" />;
      case 'message':
        return <MessageCircle className="h-5 w-5 text-purple-400" />;
      case 'like':
        return <Heart className="h-5 w-5 text-red-400" />;
      case 'system':
      default:
        return <AlertCircle className="h-5 w-5 text-slate-400" />;
    }
  };

  const unreadCount = notifications.filter((n) => !n.read).length;

  return (
    <div className="w-full h-full">
      {/* Header with no badge */}
      <div className="flex items-center justify-between p-3 border-b border-slate-800">
        <div className="flex items-center gap-2">
          <Bell className="h-5 w-5 text-blue-400" />
          <h3 className="font-medium text-white">Notifications</h3>
        </div>

        <div className="inline-flex items-center justify-center gap-2">
          <button
            className="text-xs text-slate-400 hover:text-white hover:bg-slate-800 rounded-md px-2 py-1 transition-colors"
            onClick={markAllAsRead}
          >
            Mark all as read
          </button>

          <div className="flex">
            <Link href="/notifications">
              <button className="text-slate-400 hover:text-white p-1 h-7 w-7 flex items-center justify-center">
                <Settings className="h-4 w-4" />
              </button>
            </Link>

            <button
              className="text-slate-400 hover:text-white p-1 h-7 w-7 flex items-center justify-center"
              onClick={onClose}
            >
              <X className="h-4 w-4" />
            </button>
          </div>
        </div>
      </div>

      {/* Notifications list */}
      <div className="max-h-[400px] overflow-y-auto">
        {notifications.length === 0 ? (
          <div className="p-6 text-center text-slate-500">
            <Bell className="h-10 w-10 mx-auto mb-2 opacity-30" />
            <p>No notifications yet</p>
          </div>
        ) : (
          <ul className="divide-y divide-slate-800/50">
            {notifications.map((notification) => (
              <li
                key={notification.id}
                className={`relative p-3 hover:bg-slate-800/30 transition-colors cursor-pointer ${
                  !notification.read ? 'bg-slate-800/60' : ''
                }`}
                onClick={() => markAsRead(notification.id)}
              >
                <Link href={notification.link} className="flex gap-3">
                  <div className="flex-shrink-0 mt-1">
                    {getNotificationIcon(notification.type)}
                  </div>

                  <div className="flex-1 min-w-0">
                    <p
                      className={`text-sm font-medium ${!notification.read ? 'text-white' : 'text-slate-300'}`}
                    >
                      {notification.title}
                    </p>

                    <p className="text-xs text-slate-400 mt-1 truncate">
                      {notification.message}
                    </p>

                    <p className="text-xs text-slate-500 mt-1">
                      {notification.time}
                    </p>
                  </div>

                  {!notification.read && (
                    <div className="absolute top-3 right-3 h-2 w-2 bg-blue-500 rounded-full"></div>
                  )}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Footer */}
      <div className="p-3 border-t border-slate-800 text-center">
        <Link
          href="/notifications"
          className="text-sm text-blue-400 hover:text-blue-300 transition-colors font-medium inline-flex items-center"
        >
          View all notifications
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 ml-1"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
}
