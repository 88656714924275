// lib/AuthContext.tsx
'use client';

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
// Import Router directly instead of useRouter hook
import { useRouter } from 'next/navigation';

export interface User {
  id?: string;
  _id?: string;
  name?: string;
  email?: string;
  image?: string;
  admin?: boolean;
  profilePicture?: string;
  telegramId?: string;
  socialMedia?: {
    twitter?: string;
    instagram?: string;
    tiktok?: string;
  };
  // Added for new dashboard compatibility
  displayName?: string;
  photoURL?: string;
}

interface AuthContextType {
  user: User | null;
  loading: boolean;
  login: (userData: any) => void;
  logout: () => void;
  logOut: () => void; // Alias for logout
  updateUserProfile: (updates: Partial<User>) => void;
}

// Create context with default values
const AuthContext = createContext<AuthContextType>({
  user: null,
  loading: true,
  login: () => {},
  logout: () => {},
  logOut: () => {},
  updateUserProfile: () => {},
});

export function useAuth(requireAuth = false, redirectTo = '') {
  const router = useRouter();
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (requireAuth && !loading && !user) {
      router.push(redirectTo || '/auth');
    }
  }, [user, loading, requireAuth, redirectTo, router]);

  const context = useContext(AuthContext);

  return context;
}

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const router = useRouter();

  // Initialize user from localStorage on component mount
  useEffect(() => {
    const initAuth = () => {
      try {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
          const userData = JSON.parse(storedUser);

          // Ensure ID consistency and provide a fallback
          if (!userData.id && !userData._id) {
            console.error('User data missing ID');
            setLoading(false);
            return;
          }

          const normalizedUser = {
            ...userData,
            id: userData.id || userData._id || '', // Ensure it's always a string
            displayName: userData.name, // Add displayName for compatibility
            photoURL: userData.profilePicture || userData.image, // Add photoURL for compatibility
          };

          setUser(normalizedUser);
        }
      } catch (error) {
        console.error('Error initializing auth:', error);
      } finally {
        setLoading(false);
      }
    };

    // Only run on client side
    if (typeof window !== 'undefined') {
      initAuth();
    }
  }, []);

  const login = (userData: User) => {
    try {
      // Validate that we have an ID
      if (!userData.id && !userData._id) {
        console.error('Login failed: User data missing ID');
        return;
      }

      // Normalize user data with ID validation
      const normalizedUser = {
        ...userData,
        id: userData.id || userData._id || '', // Ensure it's always a string
        displayName: userData.name, // Add displayName for compatibility
        photoURL: userData.profilePicture || userData.image, // Add photoURL for compatibility
      };

      // Store in localStorage
      localStorage.setItem('user', JSON.stringify(normalizedUser));

      // Update state
      setUser(normalizedUser);

      // Handle redirect if callbackUrl is in the URL
      if (typeof window !== 'undefined') {
        const urlParams = new URLSearchParams(window.location.search);
        const callbackUrl = urlParams.get('callbackUrl');

        if (callbackUrl) {
          router.push(decodeURIComponent(callbackUrl));
        } else {
          // Add default redirect based on user role
          const redirectPath = normalizedUser.admin ? '/admin' : '/dashboard';
          router.push(redirectPath);
        }
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  const logout = async () => {
    try {
      // Clear client-side storage
      localStorage.removeItem('user');
      localStorage.removeItem('token');

      // Call API to handle server-side logout
      try {
        const response = await fetch('/api/signout', {
          method: 'POST',
          credentials: 'include',
        });

        if (!response.ok) {
          console.warn('Server logout request failed:', response.status);
        }
      } catch (apiError) {
        console.warn('Error calling signout API:', apiError);
      }

      // Update state
      setUser(null);

      // Redirect to auth page
      if (typeof window !== 'undefined') {
        router.push('/auth');
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  // Alias for logout
  const logOut = logout;

  const updateUserProfile = (updates: Partial<User>) => {
    if (!user) return;

    try {
      const updatedUser = {
        ...user,
        ...updates,
        displayName: updates.name || user.displayName || user.name,
        photoURL:
          updates.profilePicture ||
          updates.image ||
          user.photoURL ||
          user.profilePicture ||
          user.image,
      };
      localStorage.setItem('user', JSON.stringify(updatedUser));
      setUser(updatedUser);
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  return (
    <AuthContext.Provider
      value={{ user, loading, login, logout, logOut, updateUserProfile }}
    >
      {children}
    </AuthContext.Provider>
  );
};
