'use client';

import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetchWithAuth } from '@/lib/fetchWithAuth';
import { useUI } from '@/context/UIContext';
import { Listing } from '@/types/listing';

export interface CartItem {
  _id: string;
  name: string;
  model: string;
  brand: string;
  price: number;
  image: string;
  quantity: number;
  sku?: string;
}

interface AddToCartResponse {
  success: boolean;
  message: string;
}

interface CartContextType {
  cartItems: CartItem[];
  addToCart: (item: CartItem | Listing) => Promise<AddToCartResponse>;
  removeFromCart: (itemIdOrIndex: string | number) => void;
  updateQuantity: (itemId: string, quantity: number) => void;
  clearCart: () => void;
  isItemInCart: (itemId: string) => boolean;
  cartTotal: number;
  itemCount: number;
}

// Provide default values
const CartContext = createContext<CartContextType>({
  cartItems: [],
  addToCart: async () => ({
    success: false,
    message: 'Context not initialized',
  }),
  removeFromCart: () => {},
  updateQuantity: () => {},
  clearCart: () => {},
  isItemInCart: () => false,
  cartTotal: 0,
  itemCount: 0,
});

export function CartProvider({ children }: { children: React.ReactNode }) {
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [mounted, setMounted] = useState(false);
  const { showToast, showError } = useUI();

  // Computed values
  const cartTotal = cartItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );
  const itemCount = cartItems.reduce((count, item) => count + item.quantity, 0);

  // Initialize cart from localStorage on component mount
  useEffect(() => {
    setMounted(true);
    try {
      const savedCart = localStorage.getItem('cart');
      if (savedCart) {
        setCartItems(JSON.parse(savedCart));
      }
    } catch (error) {
      console.error('Error loading cart:', error);
    }
  }, []);

  // Update localStorage when cart changes
  useEffect(() => {
    if (mounted) {
      localStorage.setItem('cart', JSON.stringify(cartItems));
    }
  }, [cartItems, mounted]);

  const addToCart = async (
    item: CartItem | Listing
  ): Promise<AddToCartResponse> => {
    try {
      // Convert item to CartItem format first
      const cartItem = asCartItem(item);

      // Check if item already exists in cart
      const existingItemIndex = cartItems.findIndex(
        (existingItem) => existingItem._id === cartItem._id
      );

      if (existingItemIndex >= 0) {
        // Update quantity if item already exists
        const updatedItems = [...cartItems];
        updatedItems[existingItemIndex].quantity += cartItem.quantity;
        setCartItems(updatedItems);

        // Display success message
        showToast(`Updated quantity of ${cartItem.name} in cart`);
        return { success: true, message: 'Item quantity updated in cart' };
      } else {
        // Add new item to cart
        setCartItems([...cartItems, cartItem]);

        // Display success message
        showToast(`${cartItem.name} added to cart`);
        return { success: true, message: 'Item added to cart' };
      }

      // In a real app, you might want to sync with a server
      // const response = await fetchWithAuth('/api/cart/add', {
      //   method: 'POST',
      //   body: JSON.stringify(item),
      // });
      // const data = await response.json();
      // if (data.success) {
      //   // Update local cart state
      //   ...
      // }
    } catch (error) {
      console.error('Error adding to cart:', error);
      showError('Failed to add item to cart');
      return { success: false, message: 'Error adding item to cart' };
    }
  };

  const removeFromCart = (itemIdOrIndex: string | number) => {
    if (typeof itemIdOrIndex === 'number') {
      // Remove by index
      setCartItems((currentItems) =>
        currentItems.filter((_, index) => index !== itemIdOrIndex)
      );
    } else {
      // Remove by ID
      setCartItems((currentItems) =>
        currentItems.filter((item) => item._id !== itemIdOrIndex)
      );
    }
  };

  const updateQuantity = (itemId: string, quantity: number) => {
    try {
      if (quantity < 1) {
        removeFromCart(itemId);
        return;
      }

      const updatedItems = cartItems.map((item) =>
        item._id === itemId ? { ...item, quantity } : item
      );

      setCartItems(updatedItems);
    } catch (error) {
      console.error('Error updating quantity:', error);
      showError('Failed to update quantity');
    }
  };

  const clearCart = () => {
    setCartItems([]);
  };

  const isItemInCart = (itemId: string) => {
    return cartItems.some((item) => item._id === itemId);
  };

  const asCartItem = (item: Listing | CartItem): CartItem => {
    // Check if it's already a CartItem
    if ('quantity' in item) {
      return item as CartItem;
    }

    // It's a MarketplaceListing, so convert it
    return {
      _id: item._id || '',
      name:
        item.title ||
        `${item.brand || ''} ${item.model || ''}`.trim() ||
        'Untitled Listing',
      price:
        typeof item.price === 'number'
          ? item.price
          : parseFloat(item.price.toString()) || 0,
      image: item.thumbnail || '',
      brand: item.brand || '', // Provide empty string as fallback
      model: item.model || '',
      quantity: 1,
    };
  };

  // Only render children when mounted on client
  if (!mounted) {
    return null;
  }

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        updateQuantity,
        clearCart,
        isItemInCart,
        cartTotal,
        itemCount,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export const useCart = () => useContext(CartContext);
