//navbar.tsx

'use client';
import { useEffect, useState, useMemo } from 'react';
import { Button } from '@/components/ui/button';
import { AvatarImage, Avatar } from '@/components/ui/avatar';
import Link from 'next/link';
import { useAuth } from '@/lib/AuthContext';
import { usePathname, useRouter } from 'next/navigation';
import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';
import {
  Bell,
  ShoppingBag,
  Menu,
  X,
  LayoutDashboard,
  Wallet,
  LineChart,
  BookOpen,
  Info,
  User,
  Settings,
  Heart,
  LogOut,
  MessageCircle,
  Search,
  ChevronRight,
} from 'lucide-react';
import { ProfilePanel } from './ProfilePanel';
import { useCart } from '@/contexts/CartContext';
import { useUI } from '@/context/UIContext';
import { NotificationPanel } from './NotificationPanel';
import { useNotifications } from '@/contexts/NotificationContext';

interface Navbar1Props {
  isSticky?: boolean;
  onProfilePanelChange?: (isOpen: boolean) => void;
  isTransparent?: boolean;
}

export function Navbar1({
  isSticky,
  onProfilePanelChange,
  isTransparent = false,
}: Navbar1Props) {
  const router = useRouter();
  const { user } = useAuth();
  const { cartItems } = useCart();
  const { unreadCount = 0 } = useNotifications?.() || {};
  // Get UI context with safer access
  const ui = useUI();
  const isProfileOpen = ui?.isProfileOpen || false;
  const setIsProfileOpen = ui?.setIsProfileOpen || (() => {});
  // New states for notification panel
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const pathname = usePathname();
  const [activeTab, setActiveTab] = useState(pathname);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [localProfileOpen, setLocalProfileOpen] = useState(false);
  // Update activeTab when pathname changes

  useEffect(() => {
    setActiveTab(pathname);
  }, [pathname]);

  // Track scroll position

  useEffect(() => {
    let ticking = false;

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          setScrollPosition(window.scrollY);
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  // Sync local state with context

  useEffect(() => {
    setLocalProfileOpen(isProfileOpen);
  }, [isProfileOpen]);

  // Define primary navigation items

  const navigationItems = [
    {
      name: 'Dashboard',
      path: '/dashboard',
      icon: <LayoutDashboard className="h-4 w-4 mr-2" />,
    },
    {
      name: 'Marketplace',
      path: '/marketplace',
      icon: <ShoppingBag className="h-4 w-4 mr-2" />,
    },
    {
      name: 'Charts',
      path: '/charts',
      icon: <LineChart className="h-4 w-4 mr-2" />,
    },
    {
      name: 'Blog',
      path: '/blog',
      icon: <BookOpen className="h-4 w-4 mr-2" />,
    },

    {
      name: 'Forums',
      path: '/forums',
      icon: <MessageCircle className="h-4 w-4 mr-2" />,
    },

    {
      name: 'About',
      path: '/about',
      icon: <Info className="h-4 w-4 mr-2" />,
    },
  ];

  // Handle escape key to close panels

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsProfileOpen(false);
        setIsMobileMenuOpen(false);
        setIsNotificationsOpen(false);
        setSearchOpen(false);
      }
    };

    window.addEventListener('keydown', handleEscape);

    return () => window.removeEventListener('keydown', handleEscape);
  }, [setIsProfileOpen]);

  // Handle clicking outside panels to close them

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      const isProfileClick =
        target.closest('.profile-panel') || target.closest('.profile-trigger');

      const isNotificationClick =
        target.closest('.notification-panel') ||
        target.closest('.notification-trigger');

      const isSearchClick = target.closest('.search-container');

      if (!isProfileClick && isProfileOpen) {
        setIsProfileOpen(false);
      }

      if (!isNotificationClick && isNotificationsOpen) {
        setIsNotificationsOpen(false);
      }

      if (!isSearchClick && searchOpen && !target.closest('.navbar-search')) {
        setSearchOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isProfileOpen, isNotificationsOpen, searchOpen, setIsProfileOpen]);

  const CartBadge = () => {
    const badgeCount = cartItems?.length || 0;

    if (badgeCount === 0) return null;

    return (
      <motion.span
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        className="absolute -top-2 -right-2 bg-blue-600 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center"
      >
        {badgeCount}
      </motion.span>
    );
  };

  const toggleProfileMenu = () => {
    // Close other panels first

    setIsNotificationsOpen(false);

    setSearchOpen(false);

    // Smooth transition handling

    if (!isProfileOpen) {
      // When opening, set local state first for immediate visual feedback

      setLocalProfileOpen(true);

      // Then update context

      setIsProfileOpen(true);
    } else {
      // When closing, keep local state true until animation completes

      setIsProfileOpen(false);

      // Local state will be updated by the useEffect
    }
  };

  const toggleNotifications = () => {
    // Close other open panels first

    setIsProfileOpen(false);

    setSearchOpen(false);

    setIsNotificationsOpen(!isNotificationsOpen);
  };

  const toggleSearch = () => {
    // Close other open panels first

    setIsProfileOpen(false);

    setIsNotificationsOpen(false);

    setSearchOpen(!searchOpen);

    if (!searchOpen) {
      // Focus search input when opened

      setTimeout(() => {
        const searchInput = document.getElementById('navbar-search');

        if (searchInput) searchInput.focus();
      }, 100);
    }
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();

    if (searchQuery.trim()) {
      router.push(`/search?q=${encodeURIComponent(searchQuery)}`);

      setSearchOpen(false);

      setSearchQuery('');
    }
  };

  const handleLinkClick = () => {
    setIsMobileMenuOpen(false);

    setIsProfileOpen(false);

    setIsNotificationsOpen(false);
  };

  // Dynamic navbar styling based on scroll and transparency setting

  const navbarClasses = `fixed top-0 left-0 right-0 z-navbar transition-all duration-300 ${
    scrollPosition > 10 || !isTransparent
      ? 'bg-slate-900/95 backdrop-blur-md shadow-lg'
      : isTransparent
        ? 'bg-transparent'
        : 'bg-slate-900'
  }`;

  // Memoize expensive calculations or components

  const navigationElements = useMemo(() => {
    return navigationItems.map((item) => (
      <Link
        key={item.path}
        href={item.path}
        onClick={handleLinkClick}
        className={`px-3 py-2 rounded-md text-sm font-medium flex items-center transition-all duration-200 ${
          activeTab === item.path
            ? 'text-white bg-slate-800 shadow-inner shadow-blue-500/20'
            : 'text-slate-300 hover:text-white hover:bg-slate-800/50'
        }`}
      >
        {item.icon}

        {item.name}
      </Link>
    ));
  }, [navigationItems, activeTab, handleLinkClick]);

  const handleLogout = () => {
    // Implement logout functionality

    console.log('Logging out');
  };

  return (
    <>
      <header className={navbarClasses}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            {/* Logo */}

            <div className="flex-shrink-0">
              <Link
                href="/"
                className="flex items-center"
                onClick={handleLinkClick}
              >
                <Image
                  src="/watchinspect.svg"
                  alt="Watch Inspect Logo"
                  width={16}
                  height={16}
                  className="h-8 w-auto"
                />

                <span className="ml-2 text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-slate-400">
                  Watch Inspect
                </span>
              </Link>
            </div>

            {/* Desktop Navigation */}

            <nav className="hidden md:flex space-x-1">{navigationElements}</nav>

            {/* Search and Right Controls */}

            <div className="flex items-center gap-1">
              {/* Search Button */}

              <Button
                variant="ghost"
                size="icon"
                onClick={toggleSearch}
                className="text-slate-300 hover:text-white hover:bg-white/10"
                type="button"
              >
                <Search className="h-5 w-5" />
              </Button>

              {/* Notifications */}

              <div className="relative notification-trigger">
                <Button
                  variant="ghost"
                  onClick={toggleNotifications}
                  className="relative group hover:bg-white/10"
                  type="button"
                >
                  <Bell className="h-5 w-5 text-gray-200 group-hover:text-blue-400" />

                  {unreadCount > 0 && (
                    <motion.span
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      className="absolute -top-1 -right-1 bg-red-500 text-white text-xs font-bold rounded-full h-4 w-4 flex items-center justify-center"
                    >
                      {unreadCount}
                    </motion.span>
                  )}
                </Button>

                {/* Notification Panel */}

                <AnimatePresence>
                  {isNotificationsOpen && (
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10 }}
                      transition={{ duration: 0.2 }}
                      className="notification-panel absolute right-0 mt-2 w-80 bg-slate-900 border border-slate-700 shadow-xl rounded-xl overflow-hidden z-dropdown"
                    >
                      <NotificationPanel
                        onClose={() => setIsNotificationsOpen(false)}
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              {/* Cart Button */}

              <Link href="/checkout" onClick={handleLinkClick}>
                <Button
                  variant="ghost"
                  className="relative group hover:bg-white/10"
                  type="button"
                >
                  <ShoppingBag className="h-5 w-5 text-gray-200 group-hover:text-blue-400" />

                  <CartBadge />
                </Button>
              </Link>

              {/* User Profile */}

              {user ? (
                <div className="relative profile-trigger">
                  <Button
                    variant="ghost"
                    onClick={toggleProfileMenu}
                    className="group hover:bg-white/10"
                    type="button"
                  >
                    <Avatar className="h-8 w-8 ring-2 ring-blue-500/50 group-hover:ring-blue-400 transition-all duration-200">
                      <AvatarImage
                        alt="User avatar"
                        src={user?.profilePicture || '/reddit.webp'}
                        className="object-cover"
                      />
                    </Avatar>
                  </Button>
                </div>
              ) : (
                <Link href="/auth" onClick={handleLinkClick}>
                  <Button className="bg-blue-600 hover:bg-blue-700 text-white">
                    Login
                  </Button>
                </Link>
              )}

              {/* Mobile menu button */}

              <div className="flex md:hidden">
                <Button
                  variant="ghost"
                  onClick={() => {
                    setIsMobileMenuOpen(!isMobileMenuOpen);

                    setIsProfileOpen(false);

                    setIsNotificationsOpen(false);
                  }}
                  className="p-2 hover:bg-white/10 transition-colors duration-200"
                  type="button"
                >
                  {isMobileMenuOpen ? (
                    <X className="h-6 w-6 text-gray-200" />
                  ) : (
                    <Menu className="h-6 w-6 text-gray-200" />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>

        {/* Search Panel */}

        <AnimatePresence>
          {searchOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="search-container w-full bg-slate-900 border-t border-slate-800 z-dropdown"
            >
              <div className="max-w-3xl mx-auto py-4 px-4">
                <form onSubmit={handleSearch} className="relative">
                  <input
                    id="navbar-search"
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search watches, brands, models..."
                    className="w-full bg-slate-800 border border-slate-700 rounded-lg pl-10 pr-4 py-3 text-white placeholder:text-slate-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />

                  <Search className="absolute left-3 top-3.5 h-5 w-5 text-slate-500" />

                  <Button
                    type="submit"
                    className="absolute right-2 top-2 bg-blue-600 hover:bg-blue-700 text-white px-3 py-1 rounded-md"
                  >
                    Search
                  </Button>
                </form>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </header>

      {/* Add spacing below navbar */}

      <div className="pt-16"></div>

      {/* Mobile Navigation Menu with enhanced styling */}

      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            initial={{ opacity: 0, x: '100%' }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: '100%' }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            className="fixed top-16 right-0 bottom-0 w-full md:hidden sm:w-80 bg-gradient-to-b from-slate-900 to-slate-950 z-dropdown overflow-y-auto shadow-xl border-l border-white/10"
          >
            {/* Decorative elements */}

            <div className="absolute inset-0 overflow-hidden pointer-events-none">
              <div className="absolute top-0 right-0 w-full h-full bg-[radial-gradient(circle_at_top_right,rgba(59,130,246,0.15),transparent_60%)]" />

              <div className="absolute bottom-0 left-0 w-full h-full bg-[radial-gradient(circle_at_bottom_left,rgba(124,58,237,0.1),transparent_60%)]" />
            </div>

            <div className="px-4 py-6 space-y-6 relative z-10">
              {/* User Section */}

              {user && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  className="p-4 rounded-xl bg-gradient-to-br from-blue-500/10 via-transparent to-purple-500/10 border border-white/10 backdrop-blur-sm"
                >
                  <div className="flex items-center space-x-4">
                    <Avatar className="h-12 w-12 ring-2 ring-blue-500/50 ring-offset-2 ring-offset-slate-900">
                      <AvatarImage
                        alt="User avatar"
                        src={user?.profilePicture || '/reddit.webp'}
                        className="object-cover"
                      />
                    </Avatar>

                    <div>
                      <p className="text-sm font-medium text-white">
                        {user.name || 'User'}
                      </p>

                      <p className="text-xs text-gray-400">{user.email}</p>
                    </div>
                  </div>

                  <div className="mt-4 flex space-x-2">
                    <Button
                      onClick={() => {
                        setIsMobileMenuOpen(false);

                        setIsProfileOpen(true);
                      }}
                      className="flex-1 bg-blue-500/10 hover:bg-blue-500/20 text-blue-400 hover:text-blue-300 text-sm rounded-lg transition-all duration-300 border border-blue-500/20"
                      variant="ghost"
                      size="sm"
                    >
                      View Profile
                    </Button>

                    <Button
                      onClick={handleLogout}
                      className="flex-1 bg-red-500/10 hover:bg-red-500/20 text-red-400 hover:text-red-300 text-sm rounded-lg transition-all duration-300 border border-red-500/20"
                      variant="ghost"
                      size="sm"
                    >
                      Logout
                    </Button>
                  </div>
                </motion.div>
              )}

              {/* Navigation Links */}

              <motion.div
                className="space-y-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ staggerChildren: 0.05, delayChildren: 0.1 }}
              >
                <h3 className="text-sm font-medium text-gray-400 uppercase tracking-wider mb-2 px-1">
                  Navigation
                </h3>

                {navigationItems.map((item, index) => (
                  <motion.div
                    key={item.name}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <Link
                      href={item.path}
                      onClick={() => {
                        handleLinkClick();

                        setIsMobileMenuOpen(false);
                      }}
                      className={`flex items-center justify-between w-full p-3 rounded-xl ${
                        pathname === item.path
                          ? 'bg-blue-600/20 text-blue-400 border border-blue-500/30'
                          : 'bg-slate-800/50 hover:bg-blue-600/10 hover:text-white text-gray-300 border border-slate-700/50 hover:border-blue-500/20'
                      } transition-all duration-300 backdrop-blur-sm group`}
                    >
                      <div className="flex items-center space-x-3">
                        <span
                          className={`${pathname === item.path ? 'text-blue-400' : 'text-gray-400 group-hover:text-blue-400'}`}
                        >
                          {item.icon}
                        </span>

                        <span>{item.name}</span>
                      </div>

                      <ChevronRight className="h-4 w-4 text-gray-500 group-hover:text-blue-400" />
                    </Link>
                  </motion.div>
                ))}
              </motion.div>

              {/* Secondary Links */}

              <motion.div
                className="space-y-1 pt-4 border-t border-white/5"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3 }}
              >
                <h3 className="text-sm font-medium text-gray-400 uppercase tracking-wider mb-2 px-1">
                  Account
                </h3>

                <Link
                  href="/settings"
                  onClick={() => {
                    handleLinkClick();

                    setIsMobileMenuOpen(false);
                  }}
                  className="flex items-center justify-between w-full p-3 rounded-xl bg-slate-800/50 hover:bg-blue-600/10 hover:text-white text-gray-300 border border-slate-700/50 hover:border-blue-500/20 transition-all duration-300 backdrop-blur-sm group"
                >
                  <div className="flex items-center space-x-3">
                    <Settings className="h-4 w-4 text-gray-400 group-hover:text-blue-400" />

                    <span>Settings</span>
                  </div>

                  <ChevronRight className="h-4 w-4 text-gray-500 group-hover:text-blue-400" />
                </Link>

                <Link
                  href="/portfolio"
                  onClick={() => {
                    handleLinkClick();

                    setIsMobileMenuOpen(false);
                  }}
                  className="flex items-center justify-between w-full p-3 rounded-xl bg-slate-800/50 hover:bg-blue-600/10 hover:text-white text-gray-300 border border-slate-700/50 hover:border-blue-500/20 transition-all duration-300 backdrop-blur-sm group"
                >
                  <div className="flex items-center space-x-3">
                    <Wallet className="h-4 w-4 text-gray-400 group-hover:text-blue-400" />

                    <span>Portfolio</span>
                  </div>

                  <ChevronRight className="h-4 w-4 text-gray-500 group-hover:text-blue-400" />
                </Link>

                {!user && (
                  <Link
                    href="/auth"
                    onClick={() => {
                      handleLinkClick();

                      setIsMobileMenuOpen(false);
                    }}
                    className="flex items-center justify-center w-full p-3 mt-4 rounded-xl bg-blue-600 hover:bg-blue-700 text-white transition-all duration-300"
                  >
                    <User className="h-4 w-4 mr-2" />

                    <span>Login / Register</span>
                  </Link>
                )}
              </motion.div>

              {/* Footer */}

              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.4 }}
                className="text-center text-xs text-gray-500 pt-4 pb-8 border-t border-white/5"
              >
                <p>© 2023 Watch Inspect. All rights reserved.</p>

                <p className="mt-1">Version 1.2.4</p>
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Profile Panel - Rendered at the root level for proper positioning */}

      <AnimatePresence>
        {localProfileOpen && (
          <ProfilePanel onClose={() => setIsProfileOpen(false)} />
        )}
      </AnimatePresence>
    </>
  );
}
