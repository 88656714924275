// components/ProfilePanel.tsx (or wherever your ProfilePanel component is located)
import { useRef, useEffect, useState } from 'react';
import { Button } from '@/components/ui/button';
import { AvatarImage, Avatar } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { useAuth } from '@/lib/AuthContext';
import { motion } from 'framer-motion';
import { useRouter } from 'next/navigation';

// Type definitions for API responses
interface ProfileResponse {
  success: boolean;
  profile: {
    id: string;
    name: string;
    email: string;
    isAdmin: boolean;
    image: string | null;
    profilePicture: string | null;
  };
  fromSession?: boolean;
}

interface PortfolioResponse {
  success: boolean;
  totalValue: number;
  watches: any[];
  error?: string;
}

import {
  Watch as WatchIcon,
  Heart as HeartIcon,
  ShoppingBag as ShoppingBagIcon,
  DollarSign as DollarSignIcon,
  Layers as LayersIcon,
  ArrowLeft as ArrowLeftIcon,
  X,
  Shield as ShieldIcon,
  Loader2,
  PlusIcon,
  SettingsIcon,
  BarChart3,
  ChevronRight,
} from 'lucide-react';

interface ProfilePanelProps {
  onClose: () => void;
}

export function ProfilePanel({ onClose }: ProfilePanelProps) {
  const { user, logout } = useAuth();
  const router = useRouter();
  const panelRef = useRef<HTMLDivElement>(null);
  const [portfolioValue, setPortfolioValue] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const handleAddWatchClick = () => {
    router.push('/portfolio?action=add');
    onClose();
  };

  const getFormattedName = (name?: string): string => {
    if (!name) return '';
    return name.charAt(0).toUpperCase() + name.slice(1);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!user?.id || !isInitialLoad) return;

      setIsLoading(true);
      try {
        // Add credentials to ensure cookies are sent
        const [profileRes, portfolioRes] = await Promise.all([
          fetch('/api/user/profile', {
            headers: {
              'Content-Type': 'application/json',
              'user-id': user.id,
            },
            credentials: 'include',
          }),
          fetch('/api/user/portfolio-value', {
            headers: {
              'Content-Type': 'application/json',
              'user-id': user.id,
            },
            credentials: 'include',
          }),
        ]);

        // Process profile data
        let profileData: ProfileResponse = {
          success: false,
          profile: {
            id: '',
            name: '',
            email: '',
            isAdmin: false,
            image: null,
            profilePicture: null,
          },
        };
        if (profileRes.ok) {
          profileData = (await profileRes.json()) as ProfileResponse;
          if (profileData.success && profileData.profile) {
            // Update user profile data from response
            if (profileData.profile.profilePicture) {
              user.profilePicture = profileData.profile.profilePicture;
            }
            if (profileData.profile.image && !user.profilePicture) {
              user.profilePicture = profileData.profile.image;
            }
          }
        } else {
          console.warn('Profile fetch failed:', profileRes.status);
        }

        // Process portfolio data
        let portfolioValue = 0;
        if (portfolioRes.ok) {
          const portfolioData =
            (await portfolioRes.json()) as PortfolioResponse;
          if (
            portfolioData.success &&
            typeof portfolioData.totalValue === 'number'
          ) {
            portfolioValue = portfolioData.totalValue;
          }
        } else {
          console.warn('Portfolio fetch failed:', portfolioRes.status);
        }

        setPortfolioValue(portfolioValue);
        setIsInitialLoad(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsInitialLoad(false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [user?.id, isInitialLoad]);

  // In ProfilePanel.tsx, update the handleLogout function
  const handleLogout = async () => {
    setIsLoggingOut(true);
    try {
      // Call the correct API endpoint
      await fetch('/api/auth/signout');
      logout();
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      setIsLoggingOut(false);
    }
  };
  const handleNavigation = (path: string) => {
    router.push(path);
    onClose();
  };

  const toggleSection = (section: string) => {
    setActiveSection(activeSection === section ? null : section);
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { type: 'spring', stiffness: 300, damping: 24 },
    },
  };

  const navItems = [
    { label: 'Portfolio', icon: WatchIcon, path: '/portfolio' },
    { label: 'Dashboard', icon: LayersIcon, path: '/dashboard', badge: 'NEW' },
    { label: 'Charts', icon: BarChart3, path: '/charts', badge: 'NEW' },
    { label: 'Purchases', icon: DollarSignIcon, path: '/purchases' },
    { label: 'Marketplace', icon: ShoppingBagIcon, path: '/marketplace' },
    { label: 'Settings', icon: SettingsIcon, path: '/settings' },
  ];

  return (
    <div
      className="fixed inset-0 z-profile flex justify-end"
      style={{
        filter: 'none',
        backdropFilter: 'none',
        isolation: 'isolate',
        willChange: 'transform', // Add hardware acceleration hint
      }}
    >
      <div
        className="fixed inset-0 bg-black/70 backdrop-blur-sm"
        onClick={onClose}
      />
      <motion.aside
        ref={panelRef}
        className="profile-panel relative w-[420px] max-w-[90vw] h-screen bg-gradient-to-b from-slate-900 to-slate-950 shadow-2xl"
        initial={{ x: '100%' }}
        animate={{ x: 0 }}
        exit={{ x: '100%' }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        style={{
          filter: 'none !important',
          backdropFilter: 'none !important',
          transform: 'translateZ(0)', // Force 3D rendering context
        }}
      >
        {/* Decorative elements */}
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          <div className="absolute top-0 right-0 w-full h-full bg-[radial-gradient(circle_at_top_right,rgba(59,130,246,0.15),transparent_60%)]" />
          <div className="absolute bottom-0 left-0 w-full h-full bg-[radial-gradient(circle_at_bottom_left,rgba(124,58,237,0.1),transparent_60%)]" />
        </div>

        {/* Header */}
        <div className="sticky top-0 z-10 bg-slate-900/80 backdrop-blur-md p-6 border-b border-white/10">
          <div className="flex justify-between items-center">
            <button
              onClick={() => handleNavigation('/')}
              className="flex items-center space-x-2 text-white/80 hover:text-blue-400 transition-all duration-300"
              type="button"
            >
              <ArrowLeftIcon className="h-5 w-5" />
              <span className="text-sm font-medium">BACK</span>
            </button>
            <Button
              variant="ghost"
              onClick={onClose}
              className="text-white/80 hover:text-blue-400 transition-all duration-300"
              type="button"
            >
              <X className="h-5 w-5" />
            </Button>
          </div>
        </div>

        {/* Scrollable Content */}
        <div className="h-[calc(100vh-88px)] overflow-y-auto custom-scrollbar">
          <motion.div
            className="px-6 py-8"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            {/* User info section */}
            <motion.div
              variants={itemVariants}
              className="relative mb-10 p-6 rounded-2xl bg-gradient-to-br from-blue-500/10 via-transparent to-purple-500/10 border border-white/10 backdrop-blur-sm shadow-xl"
            >
              <div className="flex items-start justify-between mb-6">
                <div className="flex flex-col">
                  <h2 className="text-2xl font-bold text-white mb-1 bg-clip-text text-transparent bg-gradient-to-r from-white to-blue-400">
                    Hey {getFormattedName(user?.name)}.
                  </h2>
                  <p className="text-sm text-gray-400">{user?.email}</p>
                </div>
                <Avatar className="w-16 h-16 ring-2 ring-blue-500/50 ring-offset-2 ring-offset-slate-900 transition-all duration-300 hover:ring-blue-400 shadow-lg">
                  <AvatarImage
                    alt="User avatar"
                    src={user?.profilePicture || '/reddit.webp'}
                    className="object-cover"
                  />
                </Avatar>
              </div>

              {/* Portfolio Value Card */}
              <div className="space-y-3">
                <div className="flex items-center justify-between mb-2">
                  <span className="text-sm text-gray-400 font-medium">
                    Portfolio Value
                  </span>
                  <Badge className="bg-blue-500/20 text-blue-300 px-3 border border-blue-500/30">
                    VERIFIED
                  </Badge>
                </div>
                <div className="flex items-center justify-between">
                  {isLoading ? (
                    <div className="flex items-center space-x-2">
                      <Loader2 className="h-5 w-5 animate-spin text-blue-400" />
                      <span className="text-gray-400">Calculating...</span>
                    </div>
                  ) : (
                    <>
                      <p className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-blue-400">
                        ${portfolioValue.toLocaleString()}
                      </p>
                      <Button
                        onClick={handleAddWatchClick}
                        className="ml-4 bg-blue-500/10 hover:bg-blue-500/20 text-blue-400 hover:text-blue-300 text-sm px-3 py-1 rounded-lg transition-all duration-300 flex items-center space-x-1 border border-blue-500/20"
                        variant="ghost"
                        size="sm"
                        type="button"
                      >
                        <PlusIcon className="h-4 w-4" />
                        <span>Add</span>
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </motion.div>

            {/* Navigation menu */}
            <motion.nav variants={itemVariants} className="space-y-3 mb-8">
              <h3 className="text-sm font-medium text-gray-400 uppercase tracking-wider mb-2 px-1">
                Navigation
              </h3>
              <div className="space-y-2">
                {navItems.map((item) => (
                  <motion.button
                    key={item.label}
                    onClick={() => handleNavigation(item.path)}
                    className="flex items-center justify-between w-full p-4 rounded-xl bg-slate-800/50 hover:bg-blue-600/10 hover:shadow-lg hover:shadow-blue-500/10 border border-slate-700/50 hover:border-blue-500/20 transition-all duration-300 group backdrop-blur-sm"
                    type="button"
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <div className="flex items-center space-x-3">
                      <item.icon className="h-5 w-5 text-gray-400 group-hover:text-blue-400" />
                      <span className="text-gray-300 group-hover:text-white">
                        {item.label}
                      </span>
                    </div>
                    <div className="flex items-center space-x-2">
                      {item.badge && (
                        <Badge className="bg-blue-500/20 text-blue-300 border border-blue-500/30">
                          {item.badge}
                        </Badge>
                      )}
                      <ChevronRight className="h-4 w-4 text-gray-500 group-hover:text-blue-400" />
                    </div>
                  </motion.button>
                ))}
              </div>
            </motion.nav>

            {/* Account Security Section */}
            <motion.div variants={itemVariants} className="mb-8">
              <h3 className="text-sm font-medium text-gray-400 uppercase tracking-wider mb-2 px-1">
                Account Security
              </h3>
              <div className="p-5 rounded-xl bg-gradient-to-br from-red-500/10 via-transparent to-red-500/5 border border-red-500/20 backdrop-blur-sm">
                <div className="flex items-center space-x-3 mb-3">
                  <ShieldIcon className="h-5 w-5 text-red-400" />
                  <span className="text-red-300 font-medium">
                    Security Notice
                  </span>
                </div>
                <p className="text-sm text-gray-400 leading-relaxed">
                  Remember to always keep your account secure and never share
                  your credentials. Enable two-factor authentication for
                  additional protection.
                </p>
                <Button
                  onClick={handleLogout}
                  className="w-full mt-4 bg-red-500/10 text-red-400 hover:bg-red-500/20 hover:text-red-300 transition-all duration-300 border border-red-500/20"
                  type="button"
                  disabled={isLoggingOut}
                >
                  {isLoggingOut ? (
                    <div className="flex items-center justify-center">
                      <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                      <span>Logging out...</span>
                    </div>
                  ) : (
                    'Logout'
                  )}
                </Button>
              </div>
            </motion.div>

            {/* Footer */}
            <motion.div
              variants={itemVariants}
              className="text-center text-xs text-gray-500 pt-4 pb-8 border-t border-white/5"
            >
              <p>© 2021 - 2025 Watch Inspect. All rights reserved.</p>
              <p className="mt-1">Version 1.2.4</p>
            </motion.div>
          </motion.div>
        </div>
      </motion.aside>

      {/* Custom scrollbar styles */}
      <style
        dangerouslySetInnerHTML={{
          __html: `
        .custom-scrollbar::-webkit-scrollbar {
          width: 4px;
        }
        .custom-scrollbar::-webkit-scrollbar-track {
          background: transparent;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb {
          background-color: rgba(59, 130, 246, 0.3);
          border-radius: 4px;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
          background-color: rgba(59, 130, 246, 0.5);
        }
        /* Override any parent blur filters */
        .profile-panel {
          filter: none !important;
          backdrop-filter: none !important;
        }
        `,
        }}
      />
    </div>
  );
}
